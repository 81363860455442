import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DialogComponent, ToastComponent } from 'mis-component-library';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [
      RouterModule,
      CommonModule,
      DialogComponent,
      ToastComponent,
      TranslateModule
    ]
})
export class AppComponent {
  constructor(private readonly translateService: TranslateService) {
    this.translateService.use(localStorage.getItem('lang') ?? 'en');
  }
}
