import { Router, UrlTree } from "@angular/router";
import { inject } from "@angular/core";
import { StaticDataService } from "../services/static-data/static-data.service";
import { ENV } from "src/providers/environment.provider";
import { Environment } from "src/types/environment";
import { LoginService } from "login";
import { DEV_LOGIN_ROUTE_CONFIG } from "../app.routes";

export class AuthGuard {
    public static async canLoadApp(): Promise<UrlTree | boolean> {
        const router = inject(Router);
        const staticDataService: StaticDataService = inject(StaticDataService);

        const validationResult = await AuthGuard.validateUser(router);
        if (validationResult != null) { return validationResult; }       

        await staticDataService.refreshStaticData();
        
        return true;
    }

    public static async canSelectOrganisation(): Promise<UrlTree | boolean> {
        const router = inject(Router);
        const validationResult = await AuthGuard.validateUser(router);
        if (validationResult != null) { return validationResult; }   

        return true;
    }

    private static async validateUser(router: Router): Promise<UrlTree | boolean | null> {
        const environment = inject<Environment>(ENV);
        const loginService: LoginService = inject(LoginService);
        if (!await loginService.getUserEmail()) {
            if (!environment.loginHost) { return router.createUrlTree([DEV_LOGIN_ROUTE_CONFIG.loginPath]); }

            location.href = `${environment.loginHost}/login?redirect=${location.href}`;
            return false;
        }
        return null;
    }
}