import { Routes } from '@angular/router';
import { NotFoundPageComponent } from './framework/pages/not-found/not-found-page.component';
import { AuthGuard } from './guards/auth.guard';
import { environment } from 'src/environments/environment';
import { LoginRoutingModule } from 'login';
import { LoginPathConfig } from 'login/lib/types/login-config';

export const DEV_LOGIN_ROUTE_CONFIG: LoginPathConfig = {
  loginPath: 'login',
  newPasswordPath: 'login/new-password'
};

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'app' },
  {
    path: 'app',
    canActivate: [AuthGuard.canLoadApp],
    loadChildren: () => import('./framework/framework.routes').then((m) => m.routes),
  },
];

// Add login routes dynamically if `loginHost` is not defined in the environment.
if (!environment.loginHost) {
  routes.push(...LoginRoutingModule.getRoutes(DEV_LOGIN_ROUTE_CONFIG));
}

// Catch-all route for 404
routes.push({
  path: '**',
  component: NotFoundPageComponent,
  title: 'Active F - Page Not Found',
});

export default routes;
