import { enableProdMode, inject, provideAppInitializer } from '@angular/core';
import { environment } from './environments/environment';
import { Amplify } from 'aws-amplify';
import { CookieStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { bootstrapApplication } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpBackend, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { DateFnsAdapter, MAT_DATE_FNS_FORMATS } from '@angular/material-date-fns-adapter';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideRouter } from '@angular/router';
import { ApiInterceptorService } from '@services/api-interceptor/api-interceptor.service';
import { PermissionsService } from '@services/permissions/permissions.service';
import { enGB } from 'date-fns/locale';
import { CONFIG, LoginService } from 'login';
import { ENV } from './providers/environment.provider';
import { provideTranslateService, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import routes, { DEV_LOGIN_ROUTE_CONFIG } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { provideNoopAnimations } from '@angular/platform-browser/animations';

if (environment.production) {
  enableProdMode();
}

// Configure amplify 
Amplify.configure({ Auth: { Cognito: environment.cognitoConfig } });
cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({ sameSite: 'none' }));

bootstrapApplication(AppComponent, {
  providers: [
    provideNoopAnimations(),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true },
    { provide: ENV, useValue: environment },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always', appearance: "outline" } },
    provideAppInitializer(() => {
      const initializerFn = (initializeApp)(inject(PermissionsService), inject(LoginService));
      return initializerFn();
    }),
    { provide: MAT_DATE_LOCALE, useValue: enGB },
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
    provideTranslateService({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    LoginService,
    {
      provide: CONFIG,
      useValue: {
        licenseServerUrl: environment.licenseServerUrl,
        pathConfig: !environment.loginHost ? DEV_LOGIN_ROUTE_CONFIG : undefined
      }
    },
  ],
})
  .catch(err => console.error(err));

function initializeApp(permissionsService: PermissionsService, loginService: LoginService): () => Promise<void> {
  return async (): Promise<void> => {
    // If user is not logged in, skip permission check. They will be redirect to login during routing
    const email = await loginService.getUserEmail()
    if (email) { await permissionsService.getUserPermissions(); }
  }
}

// AoT requires an exported function for factories
function HttpLoaderFactory(handler: HttpBackend): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(handler, [
    { prefix: './assets/i18n/', suffix: '.json' },
    { prefix: './assets/i18n/framework/', suffix: '.json' },
    { prefix: './assets/i18n/modules/home/', suffix: '.json' },
    { prefix: './assets/i18n/modules/purchasing/', suffix: '.json' },
    { prefix: './assets/i18n/modules/sales/', suffix: '.json' },
    { prefix: './assets/i18n/modules/assets/', suffix: '.json' },
    { prefix: './assets/i18n/modules/consolidations-revaluations/', suffix: '.json' },
    { prefix: './assets/i18n/modules/equity/', suffix: '.json' },
    { prefix: './assets/i18n/modules/journals/', suffix: '.json' },
    { prefix: './assets/i18n/modules/tax/', suffix: '.json' },
    { prefix: './assets/i18n/modules/forecasting-budgeting/', suffix: '.json' },
    { prefix: './assets/i18n/modules/treasury/', suffix: '.json' },
    { prefix: './assets/i18n/modules/configuration/', suffix: '.json' },
    { prefix: './assets/i18n/modules/shared/', suffix: '.json' },
    { prefix: './assets/i18n/pages/not-found/', suffix: '.json' }
  ]);
}